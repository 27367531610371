<template>
  <div>
    <section>
      <div class="box">
        <!-- Login Form -->
        <HgLoginForm :loading="loading" @onSubmit="login">
          <template v-if="errors" #errors>
            <HgAlertBox :title="$t('errorHandling.errorBoxTitle')" type="error">
              <APIErrors :errors="errors" />
            </HgAlertBox>
          </template>

          <template #success>
            <div v-if="success">
              <HgAlertBox :title="$t('authSuccessTitle')" type="success">
                <p>
                  {{ $t('loginSuccess') }}
                </p>
              </HgAlertBox>
              <!-- <div v-if="redirectUrl" class="form-row mt-4">
                <HgButton color="teal" tag="a" :href="redirectUrl">
                  {{ $t('back') }}
                  {{ $t('toRaffle') }}
                </HgButton>
              </div> -->
            </div>
          </template>
          <template #form-footer>
            <nuxt-link
              :to="localePath('auth-forgot-password')"
              class="hg-text-link link"
            >
              {{ $t('authModal.forgotPassword') }}
            </nuxt-link>
            <nuxt-link
              class="hg-text-link link"
              :to="localePath('auth-register')"
            >
              {{ $t('authModal.register') }}
            </nuxt-link>
          </template>
        </HgLoginForm>
      </div>
    </section>

    <client-only>
      <StroeerLoader />
    </client-only>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  components: {
    StroeerLoader: () => import('@/components/partials/StroeerLoader.vue'),
    APIErrors: () => import('@/components/ui/APIErrors')
  },
  mixins: [GtmEventsMixin],
  layout: 'guest',
  data: () => ({
    redirectUrl: null,
    errors: null,
    loading: false,
    success: false
  }),
  head() {
    return {
      title: this.$i18n.t('seo.login.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$i18n.t('seo.login.description')
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$i18n.t('seo.login.title')
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$i18n.t('seo.login.description')
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loggedInUser: 'auth/loggedInUser'
    })
  },
  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        let redirectUrl = this.$cookies.get('hg-login-redirect');

        if (this.$route.query.redirect) {
          redirectUrl = decodeURIComponent(this.$route.query.redirect);
        }

        if (redirectUrl) {
          this.$cookies.remove('hg-login-redirect');

          setTimeout(() => {
            if (redirectUrl.slice(0, 1) === '/') {
              this.$router.push(redirectUrl);
            } else {
              window.location.href = redirectUrl;
            }
          }, 2000);
        }
      }
    }
  },
  methods: {
    async login(credentials) {
      this.errors = null;
      this.loading = true;

      try {
        await this.$store.dispatch('auth/login', credentials);

        this.$toast.success(this.$i18n.t('welcome'));
        // GTM Event
        this.$gtm.push({ event: 'login_success' });
        // UserID Cookie
        this.$cookies.set('hg-user-id', this.loggedInUser.id);

        this.success = true;
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
